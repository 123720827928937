<template>
<AppLayout>
    <template v-slot:appContent>
        <div>
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Customer Care/View</h4>
                <div>
                    <router-link :to="{...previousRoute}">
                        <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
                    </router-link>
                </div>
            </div>

            <div class="content-body">
                <section class="page-user-profile">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-block d-sm-flex mb-2 align-items-center">
                                        <div class="mr-2 position-relative">
                                            <img :src="employee.avatar" alt="users avatar" class="users-avatar-shadow rounded-circle" height="120" width="120">
                                            <div class="position-absolute update-image-avatar d-flex align-items-center justify-content-center" @click.capture="openImageUpdateModal()">
                                                <i class='bx bxs-camera'></i>
                                            </div>
                                        </div>
                                        <div class="media-body ">
                                            <h4 class="media-heading text-capitalize">{{ userFullName }}</h4>
                                            <h6 v-if="user.role.name" class="pb-1">{{ `${user.role.type} / ${user.role.name}` }}</h6>
                                            <h6 class="badge" :class="status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                                                {{ status }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!--                   user information-->
                                <div class="col-lg-3">
                                    <div class="card ">
                                        <div class="card-body">
                                            <h5 class="mb-1">User Information <span style="cursor: pointer;" data-toggle="modal" data-target="#userDataUpdateModal" title="Edit" class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span></h5>
                                            <ul class="list-unstyled">
                                                <li class="mb-1 text-capitalize">
                                                    <span class="text-bold-700 mb-1" style="text-transform: capitalize;">First Name: </span>
                                                    <span>{{ user.firstName }}</span>
                                                </li>
                                                <li class="mb-1 text-capitalize">
                                                    <span class="text-bold-700 mb-1" style="text-transform: capitalize;">Last Name: </span>
                                                    <span>{{ user.lastName }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Phone Number: </span>
                                                    <span>{{ user.phoneNumber || 'Not Provided' }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Email: </span>
                                                    <span>{{ user.email || 'Not Provided' }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Date Of Birth: </span>
                                                    <span>{{ user.dateOfBirth || 'Not Provided' }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Created at: </span>
                                                    <span>{{ createdAt }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Last Updated at: </span>
                                                    <span>{{ updatedAt }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <!--                   employee and bank details information-->
                                <div class="col-lg-5 mb-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div>
                                                <h5 class="card-title">Employee Information
                                                    <span style="cursor: pointer;" @click="openEmployeeUpdateModal()" title="Edit" class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span></h5>
                                                <div v-if="employee.designation" class="row">
                                                    <div class="col-sm-6 col-12 text-capitalize">
                                                        <h6><small class="text-muted pb-0">Designation:</small></h6>
                                                        <p>{{ employee.designation }}</p>
                                                    </div>
                                                    <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                                        <h6><small class="text-muted">ID Number:</small></h6>
                                                        <p>{{ employee.idNumber }}</p>
                                                    </div>
                                                    <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                                        <h6><small class="text-muted">Salary amount:</small></h6>
                                                        <p>{{ parseFloat(employee.salaryAmount / 100).toFixed(2) }}</p>
                                                    </div>
                                                    <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                                        <h6><small class="text-muted">Salary type:</small></h6>
                                                        <p>{{ employee.salaryType }}</p>
                                                    </div>
                                                    <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                                        <h6><small class="text-muted">ABN:</small></h6>
                                                        <p>{{ employee.abn }}</p>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <p>No Employee Data Provided</p>
                                                </div>
                                            </div>
                                            <hr class="my-2">

                                            <div>
                                                <h5 class="card-title">Address Information <span style="cursor: pointer;" data-toggle="modal" data-target="#addressUpdateModal" title="Edit" class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span></h5>

                                                <div v-if="employee.address.street" class="row">
                                                    <div class="col-sm-12 col-12">
                                                        <h6><small class="text-muted">FULL ADDRESS: </small></h6>
                                                        <p>{{ userFullAddress }}</p>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <p>No Address Assigned</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 mb-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">Bank Information
                                                <span style="cursor: pointer;" data-toggle="modal" data-target="#bankInfoUpdateModal" title="Edit" class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span>
                                            </h5>
                                            <div v-if="user.bankAccount.accountName && user.bankAccount.accountNumber" class="row">
                                                <div class="col-sm-12 col-12 pb-0">
                                                    <h6><small class="text-muted">Bank Name: </small></h6>
                                                    <p>{{ user.bankAccount.bankName }}</p>
                                                </div>
                                                <div class="col-sm-12 col-12">
                                                    <h6><small class="text-muted">Account Name: </small></h6>
                                                    <p>{{ user.bankAccount.accountName }}</p>
                                                </div>
                                                <div class="col-sm-12 col-12">
                                                    <h6><small class="text-muted">Account NUmber: </small></h6>
                                                    <p>{{ user.bankAccount.accountNumber }}</p>
                                                </div>
                                                <div class="col-sm-12 col-12">
                                                    <h6><small class="text-muted">BSB: </small></h6>
                                                    <p>{{ user.bankAccount.bsb }}</p>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>NO BANK INFO PROVIDED</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <UserUpdateModal modal-name="userDataUpdateModal" :modal-usages-for="1" :user-id="user.id" :user-data="{...user}" @updatedUserData="onUpdatedUserData" />
                <UserUpdateModal modal-name="userRoleDataUpdateModal" :modal-usages-for="2" :user-id="user.id" :get-roles-params="getRolesParams" :selected-role-data="{value:user.role.id,name:user.role.name}" :user-role-id="user.role.id" :user-data="{...user}" @updatedUserRoleData="onUpdatedUserRoleData" />
                <div class="" data-toggle="modal" data-target="#employeeDetailsUpdateModal"></div>
                <EmployeeDetailsUpdateModal v-on:getSingleView="getSingleCustomerCare($event)" :existing-employee="{...customerCare?.employee}" />
                <AddressUpdateModal modal-name="addressUpdateModal" address-update-for-model-name="employee" :model-id="id" :user-id="user.id" :selected-address-data="selectedAddressData" @updateModalAddressData="updateModalAddressData" />
                <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>
                <ImageUpdateModal v-on:getSingleView="uploadImage($event)" :previousImage="employee.avatar" />
                <BankInfoUpdateModal v-on:updatedBankData="onUpdatedBankData($event)" :user="{...user}" />
            
            </div>
        </div>
    </template>
</AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import AddressUpdateModal from "@/components/backEnd/modal/AddressUpdateModal";

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// core package
import {
    mapActions,
    mapGetters
} from "vuex";


import BankInfoUpdateModal from "@/components/backEnd/modal/BankInfoUpdateModal";
import UserUpdateModal from "@/components/backEnd/modal/UserUpdateModal";

import ImageUpdateModal from "@/components/backEnd/modal/ImageUpdateModal";
import EmployeeDetailsUpdateModal from "@/views/backEnd/users/technicians/includes/EmployeeDetailsUpdateModal";

export default {
    name: "ManagerView",

    mixins: [ShowToastMessage, Loader],

    components: {
        EmployeeDetailsUpdateModal,
        AppLayout,
        UserUpdateModal,
        /*AddressUpdateModal,*/
        /*RoleUpdateModal,*/
        
        BankInfoUpdateModal,

        ImageUpdateModal,
        AddressUpdateModal
    },
    data() {
        return {
            getSettingsParams: {
                type: ['default'],
                key: ['default_state'],
            },
            getRolesParams: {
                type: 3
            },
            id: '',
            status: '',
            createdAt: '',
            updatedAt: '',
            user: {
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                dateOfBirth: '',
                avatar: '/app-assets/defaults/images/users/avatar.svg',
                role: {
                    id: '',
                    type: '',
                    name: '',
                    description: '',
                },
                bankAccount: {
                    accountName: '',
                    accountNumber: '',
                    bankName: '',
                    bsb: '',
                    id: '',
                    userId: ''
                },
                superAdmin: {
                    id: '',
                    superAdminStatus: '',
                },
            },
            employee: {
                id: '',
                abn: '',
                idNumber: '',
                designation: '',
                salaryType: '',
                salaryAmount: '',
                avatar: '',
                address: {
                    id: '',
                    street: '',
                    suburb: '',
                    state: '',
                    postCode: '',
                    country: '',
                },
                teamLead: {
                    id: '',
                    status: '',
                }
            },

            getCustomerCareParams: {
                with_relation: [
                    'user',
                    'user.role',
                    'user.media',
                    'user.bankAccount',
                    'employee',
                    'employee.media',
                    'employee.address',

                ],
            },

            modelForUpdating: {},
        }
    },
    watch: {

    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',
            customerCare: 'appCustomerCares/customerCare'
        }),
        /*storeManagerStatus: {
          get: function () {
            return this.storeManager.status === 'Active';
          },
          set: function (newValue) {
            this.updateStoreManagerStatus(newValue);
          }
        },*/

        userFullName() {
            return `${this.user.firstName} ${this.user.lastName}`;
        },
        userFullAddress() {
            return `${this.employee.address.street}, ${this.employee.address.suburb}, ${this.employee.address.state.toUpperCase()},  ${this.employee.address.postCode}, ${this.employee.address.country}`;
        },
        selectedAddressData() {
            return {
                value: this.employee.address.id,
                name: this.userFullAddress,
            };
        },
        states() {
            return this.$store.getters['appSettings/settingDefaultState'].value
        },

        role() {
            let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
                let roleId = roleObj.id;
                let roleName = roleObj.name;
                return {
                    value: roleId,
                    name: roleName
                };
            });
            return [{
                value: '',
                name: 'None'
            }, ...activeRoles];
        },
    },
    methods: {

        ...mapActions({
            getCustomerCare: 'appCustomerCares/getCustomerCare',
            resetCustomerCare: 'appCustomerCares/resetCustomerCare',
            getSettings: 'appSettings/getSettings',
            getRoles: 'appRoles/getRoles',
            putAvatar: 'appEmployees/putAvatar',
        }),
        async updateModalAddressData(updateModalAddressData) {
            this.employee.address.id = updateModalAddressData.addressData.id;
            this.employee.address.street = updateModalAddressData.addressData.street;
            this.employee.address.suburb = updateModalAddressData.addressData.suburb;
            this.employee.address.state = updateModalAddressData.addressData.state;
            this.employee.address.postCode = updateModalAddressData.addressData.postCode;
            this.employee.address.country = updateModalAddressData.addressData.country;
        },
        openEmployeeUpdateModal() {
            document.querySelector('[data-target="#employeeDetailsUpdateModal"]').click();
        },

        openImageUpdateModal() {
            document.querySelector('[data-target="#updateAvatarModal"]').click();
        },
        async uploadImage(imageBase64) {

            const payLoad = {
                id: this.employee.id,
                data: {
                    avatar: imageBase64
                }
            }
            const response = await this.putAvatar(payLoad);
            if (response.status === 201 || response.status === 200) {
                this.loader(false);
                document.querySelector('[data-target="#updateAvatarModal"]').click();
                await this.getSingleCustomerCare(this.$route.params.id)
                // this.$emit('getSingleView', this.$route.params.id);

            }
            if (response && response.message) {
                this.showToastMessage(response);
            }
        },

        async onUpdatedEmployeeData(updateEmployeeData) {
            this.employee = {
                id: this.employee.id,
                userId: this.user.id,
                addressId: this.employee.address.id,
                designation: updateEmployeeData.designation,
                idNumber: updateEmployeeData.id_number,
                abn: updateEmployeeData.abn,
                salaryAmount: updateEmployeeData.salary_amount,
                salaryType: updateEmployeeData.salary_type
            }
            console.log(updateEmployeeData);
        },
        async onUpdatedBankData(updatedBankData) {
            this.user.bankAccount = {
                accountName: updatedBankData.account_name,
                accountNumber: updatedBankData.account_number,
                bankName: updatedBankData.bank_name,
                bsb: updatedBankData.bsb,
                id: '',
                userId: this.user.id
            }
            console.log(updatedBankData);
        },
        async onUpdatedUserRoleData(updatedUserRoleData) {
            console.log(updatedUserRoleData);
        },
        async onUpdatedUserData(updatedUserData) {
            this.user.firstName = updatedUserData.userData.firstName;
            this.user.lastName = updatedUserData.userData.lastName;
            this.user.email = updatedUserData.userData.email;
            this.user.phoneNumber = updatedUserData.userData.phoneNumber;
            this.user.dateOfBirth = updatedUserData.userData.dateOfBirth;
        },
        async getAdminTypeRoleList() {
            await this.getRoles(this.getRolesParams);
        },
        async getSettingList() {
            await this.getSettings(this.getSettingsParams);
        },
        async getSingleCustomerCare(id) {
            this.loader(true);
            let paramObj = {
                id: id,
                params: this.getCustomerCareParams,
            };

            await this.getCustomerCare(paramObj).then(async (response) => {
                this.loader(false);
                if (response.status === 200) {
                    this.id = this.customerCare.id ? this.customerCare.id : '';
                    this.status = this.customerCare.status ? this.customerCare.status : '';
                    this.createdAt = this.customerCare.created_at ? this.customerCare.created_at : '';
                    this.updatedAt = this.customerCare.updated_at ? this.customerCare.updated_at : '';

                    this.user.id = this.customerCare.user && this.customerCare.user.id ? this.customerCare.user.id : '';
                    this.user.firstName = this.customerCare.user && this.customerCare.user.first_name ? this.customerCare.user.first_name : '';
                    this.user.lastName = this.customerCare.user && this.customerCare.user.last_name ? this.customerCare.user.last_name : '';
                    this.user.email = this.customerCare.user && this.customerCare.user.email ? this.customerCare.user.email : '';
                    this.user.phoneNumber = this.customerCare.user && this.customerCare.user.phone_number ? this.customerCare.user.phone_number : '';
                    this.user.dateOfBirth = this.customerCare.user && this.customerCare.user.date_of_birth ? this.customerCare.user.date_of_birth : '';
                    this.user.avatar = this.customerCare.user && this.customerCare.user.avatar ? this.customerCare.user.avatar : '';

                    this.user.role.id = this.customerCare.user && this.customerCare.user.role && this.customerCare.user.role.id ? this.customerCare.user.role.id : '';
                    this.user.role.type = this.customerCare.user && this.customerCare.user.role && this.customerCare.user.role.type ? this.customerCare.user.role.type : '';
                    this.user.role.name = this.customerCare.user && this.customerCare.user.role && this.customerCare.user.role.name ? this.customerCare.user.role.name : '';
                    this.user.role.description = this.customerCare.user && this.customerCare.user.role && this.customerCare.user.role.description ? this.customerCare.user.role.description : '';

                    this.employee.id = this.customerCare ?.employee ?.id ?? '';
                    this.employee.avatar = this.customerCare.employee && this.customerCare.employee.avatar ? this.customerCare.employee.avatar : '/app-assets/defaults/images/users/avatar.svg';
                    this.employee.abn = this.customerCare ?.employee ?.abn ?? '';
                    this.employee.idNumber = this.customerCare ?.employee ?.id_number ?? '';
                    this.employee.designation = this.customerCare ?.employee ?.designation ?? '';
                    this.employee.salaryType = this.customerCare ?.employee ?.salary_type ?? '';
                    this.employee.salaryAmount = this.customerCare ?.employee ?.salary_amount ?? '';

                    this.employee.address.id = this.customerCare ?.employee ?.address ?.id ?? '';
                    this.employee.address.street = this.customerCare ?.employee ?.address ?.street ?? '';
                    this.employee.address.suburb = this.customerCare ?.employee ?.address ?.suburb ?? '';
                    this.employee.address.state = this.customerCare ?.employee ?.address ?.state ?? '';
                    this.employee.address.postcode = this.customerCare ?.employee ?.address ?.post_code ?? '';
                    this.employee.address.country = this.customerCare ?.employee ?.address ?.country ?? '';

                    this.employee.teamLead.id = this.customerCare ?.employee ?.teamLead ?.id ?? '';
                    this.employee.teamLead.status = this.customerCare ?.employee ?.teamLead ?.status ?? '';

                    this.user.bankAccount = {
                        accountName: this.customerCare ?.user ?.bankAccount ?.account_name ?? '',
                        accountNumber: this.customerCare ?.user ?.bankAccount ?.account_number ?? '',
                        bankName: this.customerCare ?.user ?.bankAccount ?.bank_name ?? '',
                        bsb: this.customerCare ?.user ?.bankAccount ?.bsb ?? '',
                        id: this.customerCare ?.user ?.bankAccount ?.id ?? '',
                        userId: this.user.id
                    }

                }

                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
        },

    },
    async mounted() {
        this.loader(true);
        await this.getSettingList();
        const routeParams = await this.$route.params;
        await this.getSingleCustomerCare(routeParams.id);
        // await this.getAdminTypeRoleList();
    },
    async beforeUnmount() {
        await this.resetCustomerCare();
    },
}
</script>

<style scoped>
p {
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 79%;
    right: 10px;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
    background-color: #4173ce;
}

.update-image-avatar>i {
    font-size: 20px;
    z-index: 10;
    color: white;
}

@media only screen and (max-width: 576px) {
    .update-image-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #5a8dee;
        border: 2px solid white;
        top: 64%;
        left: 92px;
        right: 0;
        z-index: 10;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
}
</style>
